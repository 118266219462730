require('./bootstrap');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

Livewire.on('gotoTop', () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behaviour: 'smooth'
  })
});

window.$ = window.jQuery = require("jquery");
require("bootstrap");
require("bootstrap-datepicker");
require("daterangepicker");
const moment = require("moment");
const parsley = require("parsleyjs");
const feather = require("feather-icons");
const notie = require("notie");

(function ($) {

  $.fn.once = function (a, b) {
    return this.each(function () {
      $(this).off(a).on(a, b);
    });
  };

  $(document).ready(function () {
    let start = moment()
      .subtract(1, "months")
      .startOf("month");
    let end = moment()
      .subtract(1, "months")
      .endOf("month");

    const params = new URLSearchParams(window.location.search);

    if (params.get("start_at")) {
      start = moment(params.get("start_at"), "YYYY-MM-DD");
    }

    if (params.get("end_at")) {
      end = moment(params.get("end_at"), "YYYY-MM-DD");
    }

    // init daterangepicker
    $('[data-plugin="daterangepicker"]').daterangepicker({
      startDate: start,
      endDate: end,
      opens: "left",

      locale: {
        format: "YYYY-MM-DD"
      },
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment()
            .subtract(1, "month")
            .startOf("month"),
          moment()
            .subtract(1, "month")
            .endOf("month")
        ],
        Lifetime: [
          moment("2019-01-01", "YYYY-MM-DD").startOf("month"),
          moment()
        ]
      }
    });

    $('[data-plugin="daterangepicker"]').on("apply.daterangepicker", function (
      ev,
      picker
    ) {
      let params = [
        "start_at=" + picker.startDate.format("YYYY-MM-DD"),
        "end_at=" + picker.endDate.format("YYYY-MM-DD")
      ];
      window.location.href = window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        params.join("&");
    });

    // init datepicker
    $('[data-plugin="datepicker"]').datepicker({
      format: "yyyy-mm-dd",
      todayBtn: "linked"
    });

    // init icons
    feather.replace({class: "feather-16", "stroke-width": 2});

    ///
    $("form").on("keypress", function (e) {
      if (e.keyCode == 13 && e.target.type != "textarea") {
        e.preventDefault();
        $(this).submit();
      }
    });

    // api interaction from forms
    $(".apiForm").on("submit", function (e) {
      e.preventDefault();
      // let formData = $(this).serialize();
      let formData = new FormData(this);
      // formData.append('file', $('#file')[0].files[0]);

      let formAction = $(this).attr("action");
      let formMethod = $(this).attr("method");
      let formEnctype = $(this).attr("enctype");
      let submitButton = $(".apiForm").find(':submit');

      let instance = $(this).parsley({
        errorClass: "is-invalid",
        successClass: "is-validz",
        errorsWrapper: '<ul class="list-unstyled text-sm mt-1 text-muted"></ul>'
      });
      instance.validate();
      if (!instance.isValid()) {
        return false;
      }
      $.ajax({
        headers: {
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
          Authorization: "Bearer " + window.app.apiToken
        },

        url: formAction,
        type: formMethod,
        enctype: formEnctype,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (response) {
          if (response.data.message) {
            notie.alert({
              type: "success",
              time: 5,
              text: response.data.message,
              position: "top"
            });

          }

          if (response.data.redirect) {
            setTimeout(function () {
              window.location.href = response.data.redirect;
            }, 800);
          }
          submitButton.attr('disabled', false);
        },
        error: function (request, status, error) {
          let response = $.parseJSON(request.responseText);

          let errors = Object.values((response.data ? response.data.errors : response.errors)).reduce(
            (r, c) => r.concat(c),
            []
          );

          let errorList = "<br><ul><li>" + errors.join("</li><li>") + "</li></ul>";

          notie.alert({
            type: "error",
            // stay: true,
            time: 5,
            text: (response.data ? response.data.message : response.message) + errorList,
            position: "top"
          });
          submitButton.attr('disabled', false);
        }
      });
    });

    // api interaction from links
    $(".apiLink").on("click", function (e) {
      e.preventDefault();
      let formAction = $(this).attr("href");
      let formMethod = $(this).attr("method");
      let formConfirm = $(this).attr("confirm");
      let modalConfirm = $("#modal-confirm-action");
      let btnConfirm = $("#btn-modal-confirm");
      let btnCancel = $("#btn-modal-cancel");

      if (formConfirm === "true") {
        modalConfirm.modal({
          show: true
        });
        btnConfirm.removeAttr("data-dismiss");
        btnConfirm.once('click', function () {
          let btnSubmit = this;
          if (btnSubmit.disabled) return;
          btnSubmit.disabled = true;
          $.ajax({
            headers: {
              "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
              Authorization: "Bearer " + window.app.apiToken
            },
            type: formMethod,
            url: formAction,
            success: function (response) {
              if (response.data === undefined) {
                return;
              }

              if (response.data.message !== undefined) {
                notie.alert({
                  type: "success",
                  time: 1,
                  text: response.data.message,
                  position: "top"
                }, modalConfirm.modal("hide"));
              }

              if (response.data.redirect !== undefined) {
                setTimeout(function () {
                  window.location.href = response.data.redirect;
                }, 500);
              }

              btnSubmit.disabled = false;

            },
            error: function (request, status, error) {
              let response = $.parseJSON(request.responseText);
              let errors = Object.values(response.data.errors).reduce((r, c) => r.concat(c), []);
              let errorList = "<br><ul><li>" + errors.join("</li><li>") + "</li></ul>";

              notie.alert({
                type: "error",
                time: 5,
                text: response.data.message + errorList,
                position: "top"
              });
              btnSubmit.disabled = false;
            }
          });

        });
        btnCancel.once('click', function () {
          modalConfirm.modal("hide");

        });
      } else {
        $.ajax({
          headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            Authorization: "Bearer " + window.app.apiToken
          },
          type: formMethod,
          url: formAction,
          success: function (response) {
            if (response.data !== undefined && response.data.message !== undefined) {
              notie.alert({
                type: "success",
                time: 5,
                text:  (response.data ? response.data.message : response.message),
                position: "top"
              });
            }

            if (response.data !== undefined && response.data.redirect !== undefined) {
              setTimeout(function () {
                window.location.href = response.data.redirect;
              }, 800);
            }
          },
          error: function (request, status, error) {
            let data = $.parseJSON(request.responseText);
            let errors = Object.values((response.data ? response.data.errors : response.errors)).reduce(
              (r, c) => r.concat(c),
              []
            );

            let errorList =
              "<br><ul><li>" + errors.join("</li><li>") + "</li></ul>";

            notie.alert({
              type: "error",
              // stay: true,
              time: 5,
              text: (response.data ? response.data.errors : response.errors) + errorList,
              position: "top"
            });
          }
        });
      }
    });

    $(".apiField").on("change", function (e) {
      e.preventDefault();
      let formAction = $(this).attr("action");
      let formMethod = $(this).attr("method");
      $.ajax({
        headers: {
          // 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
          Authorization: "Bearer " + window.app.apiToken
        },
        type: formMethod,
        url: formAction,
        data: {
          isActive: 1
        },
        success: function (data) {
          if (data.redirect) {
            console.log(data.redirect);
            window.location.href = data.redirect;
          }
        },
        error: function (request, status, error) {
          json = $.parseJSON(request.responseText);
          // alert(json.message);
          notie.alert({
            text: json.message,
            position: "top"
          });
          // console.log(json);
          // $.each(json.error, function (key, value) {
          //     $('.alert-danger').show();
          //     $('.alert-danger').append('<p>' + value + '</p>');
          // });
          // // $("#result").html('');
        }
      });
    });

    $(".custom-file input").change(function (e) {
      $(this)
        .next(".custom-file-label")
        .html(e.target.files[0].name);
    });

    $(".meterReading input").change(function (e) {
      let meterId = $(this).attr("data-id");
      let image = e.target.files[0];
      let fd = new FormData();
      fd.append("image", image);
      $.ajax({
        headers: {
          // 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
          Authorization: "Bearer " + window.app.apiToken
        },
        url: "/api/meter/" + meterId + "/uploadPhoto",
        type: "post",
        data: fd,
        contentType: false,
        processData: false,

        success: function (data) {
          $("#meter-" + meterId + "-photoUpload").remove();
          $("#meter-" + meterId + "-newindex").show();
          $("#meter-" + meterId + "-uploaded").css(
            "background-image",
            "url(" + data.url + ")"
          );
          $("#meter-" + meterId + "-uploadedPhoto").val(data.image);
        },
        error: function (request, status, error) {
          json = $.parseJSON(request.responseText);
          notie.alert({
            text: json.message,
            position: "top"
          });
        }
      });
    });

    $('form').submit(function () {
      $(this).find(':submit').attr('disabled', 'disabled');
    });
  });
})(jQuery);
